
import { defineComponent } from "vue";
import SendMessage from "@/components/thread/SendMessage.vue";
import Message from "@/components/thread/messageTypes/Message.vue";
import Feedback from "@/components/thread/messageTypes/Feedback.vue";
import { FeedbackItem } from "@/typings/FeedbackItem";

export default defineComponent({
  components: { SendMessage, Message, Feedback },

  data: () => ({
    feedback: null as null | FeedbackItem,
  }),

  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },

  methods: {
    getFeedback() {
      if ("id" in this.$route.params === false) {
        return;
      }

      this.$http
        .get("api/v1/feedback/" + this.$route.params.id)
        .then((response) => {
          this.feedback = response.data;
          this.$nextTick(() => {
            setTimeout(() => {
              this.scrollToBottom();
            }, 200);
          });
        });
    },
    scrollToBottom() {
      var mb = document.getElementById("bottomAnchor");
      if (mb) {
        mb.scrollIntoView({ behavior: "smooth" });
      }
    },
  },

  watch: {
    "$route.params.id"() {
      this.getFeedback();
    },
  },

  mounted() {
    this.getFeedback();
  },
});
