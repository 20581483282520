
import { defineComponent } from "vue";
import Dropdown from "primevue/dropdown";
import { Reward } from "@/typings/Reward";
import Dialog from "primevue/dialog";
import Button from "primevue/button";

export default defineComponent({
  data: () => ({
    quantity: 1 as number,
    quantityOptions: [
      { name: "1", value: "1" },
      { name: "2", value: "2" },
      { name: "3", value: "3" },
      { name: "4", value: "4" },
      { name: "5", value: "5" },
    ],
    reward: null as Reward | null,
    showSucessApplyRewardDialog: false,
    loyaltyPoints: 0,
  }),
  mounted() {
    this.loadRewards();
    this.loadUserLoyaltyPoints();
  },
  methods: {
    loadRewards() {
      this.$http
        .get("api/v1/loyalty/rewards/" + this.$route.params.id)
        .then((response) => {
          this.reward = response.data;
        });
    },
    orderReward() {
      if (this.reward === null) {
        return;
      }
      let params = {
        reward_id: this.reward.id,
        amount: this.quantity,
      };
      this.$http.post("api/v1/loyalty/rewards/apply", params).then(() => {
        this.loadUserLoyaltyPoints().then(
          () => (this.showSucessApplyRewardDialog = true),
        );
      });
    },
    getRewardValue() {
      let points = this.reward?.value || 0;
      return points * this.quantity;
    },
    async loadUserLoyaltyPoints() {
      this.$http
        .get("api/v1/user/" + this.user.id + "/points")
        .then((response) => {
          this.loyaltyPoints = response.data.current;
        });
    },
  },
  computed: {
    orderAvailable() {
      let points = this.reward?.value || 0;

      return points * this.quantity <= this.loyaltyPoints;
    },
    user() {
      return this.$store.getters["user/user"];
    },
  },
  components: { Dropdown, Dialog, Button },
});
