
import { defineComponent } from "vue";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import InputSwitch from "primevue/inputswitch";
import Dialog from "primevue/dialog";
import Button from "primevue/button";

export default defineComponent({
  emits: ["refresh"],
  props: {
    loyaltyPoints: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    numberOfPoints: null as null | number,
    transferAll: false,
    toUser: null as null | string,
    pointsError: null as null | string,
    emailError: null as null | string,
    regexEmail: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    showSuccessDialog: false,
    showErrorDialog: false,
  }),
  methods: {
    validate() {
      this.pointsError = null;
      this.emailError = null;

      if (!this.numberOfPoints || this.numberOfPoints < 1) {
        this.pointsError = this.$t("my_points.transfer.valid_amount");
      }

      if (!this.toUser) {
        this.emailError = this.$t("my_points.transfer.empty_email");
      }

      if (this.toUser && this.regexEmail.test(this.toUser) === false) {
        this.emailError = this.$t("error.enter_valid_email");
      }
    },
    transferPoints() {
      this.validate();

      if (this.pointsError || this.emailError) {
        return;
      }

      this.$http
        .post("/api/v1/user/transfer-points", {
          amount: this.numberOfPoints,
          to_user: this.toUser,
        })
        .then(() => {
          this.$emit("refresh");
          this.showSuccessDialog = true;
        })
        .catch(() => (this.showErrorDialog = true));
    },
    closeSuccessDialog() {
      this.showSuccessDialog = false;
      this.$router.push({ name: "MyPointsOverview" });
    },
    calculatePoints() {
      if (this.numberOfPoints) {
        const pointsLeft = this.loyaltyPoints - this.numberOfPoints;
        return this.$t("my_points.points_left", {
          points: pointsLeft,
        });
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  watch: {
    transferAll() {
      if (this.transferAll) {
        this.numberOfPoints = this.loyaltyPoints;
      } else {
        this.numberOfPoints = null;
      }
    },
  },
  components: { InputText, InputNumber, InputSwitch, Dialog, Button },
});
