
import { defineComponent, PropType } from "vue";
import { Reward } from "@/typings/Reward";

export default defineComponent({
  data: () => ({
    loyaltyPoints: 0,
  }),
  props: {
    reward: {
      type: Object as PropType<Reward>,
      required: true,
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
});
