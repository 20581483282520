
import { defineComponent } from "vue";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import { NewMessage } from "@/typings/NewMessage";
import Dialog from "primevue/dialog";
import { Coordinates, Marker } from "@/typings/Marker";
import { SelectedLocation } from "@/typings/SelectedLocation";
import { GmapPlaceResult } from "@/typings/GoogleMaps";
import { GMapMarker, GMapMap } from "@fawmi/vue-google-maps";

export default defineComponent({
  data: () => ({
    type: "",
    message: "" as string | undefined,
    selectedImage: null as null | string | ArrayBuffer | undefined,
    selectedLocation: {} as SelectedLocation,
    location: {} as SelectedLocation,
    showErrorDialog: false,
    center: {
      lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
      lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
    } as Coordinates,
    marker: {} as Marker,
    apiKey: process.env.VUE_APP_GOOGLE_MAPS_KEY,
    locationVisible: false,
  }),

  methods: {
    encodeImage(file: File) {
      this.message = "";
      let reader = new FileReader();
      reader.onload = (e) => {
        this.selectedImage = e.target?.result;
      };
      reader.readAsDataURL(file);
    },
    pickFile() {
      this.selectedImage = null;
      const allowedFileTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/gif",
      ];
      let input = this.$refs.imageUpload as HTMLInputElement;
      let file = input.files;
      if (file && file[0]) {
        if (
          file[0].size >= 5 * 1024 ** 2 ||
          !allowedFileTypes.includes(file[0].type)
        ) {
          input.value = "";
          this.selectedImage = null;
          this.showErrorDialog = true;
          return;
        }
        this.encodeImage(file[0]);
        this.location = {};
      }
    },
    openUploadImage() {
      this.selectedImage = null;
      const input = this.$refs.imageUpload as HTMLInputElement;
      input.value = "";
      input.click();
    },
    sendMessage() {
      if (!this.message?.trim()) return;

      let params: NewMessage = { text: this.message, type: "message" };

      if (this.selectedImage) {
        params.type = "image";
        params.value = this.selectedImage.toString().split("base64,").pop();
      }

      if (Object.keys(this.location).length !== 0) {
        params.type = "location";
        params.value = this.location.value?.toString().split("base64,").pop();
        params.subject = this.location.subject;
      }

      this.$http
        .post("api/v1/feedback/" + this.$route.params.id + "/message", params)
        .then(() => {
          this.clearData();
          this.$emit("messageSent");
        });
    },

    async setAddress(place: GmapPlaceResult) {
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();

      this.marker.position = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      this.center = this.marker.position;
      this.selectedLocation = {
        text: place.formatted_address,
        subject: place.place_id,
        value: await this.getPreview(latitude, longitude),
      };
    },

    async handleMapClick(place: any) {
      this.marker.position = {
        lat: place.latLng.lat(),
        lng: place.latLng.lng(),
      };
      this.selectedLocation.value = await this.getPreview(
        place.latLng.lat(),
        place.latLng.lng(),
      );
      const url = `https://maps.googleapis.com/maps/api/geocode/json?language=si&latlng=${place.latLng.lat()},${place.latLng.lng()}&key=${
        this.apiKey
      }`;

      await this.$http
        .get(url, {
          transformRequest: (data, headers) => {
            delete headers.Authorization;
            return data;
          },
        })
        .then((response) => {
          const pattern = /^(.*[a-zA-Z0-9])[,].*$/;
          this.selectedLocation.text = response.data.results[0][
            "formatted_address"
          ].replace(pattern, "$1");
          this.selectedLocation.subject = response.data.results[0]["place_id"];
        });
      this.message = this.selectedLocation.text;
    },

    async getPreview(latitude: number, longitude: number): Promise<string> {
      const loc = `${latitude},${longitude}`;
      const url = `https://maps.googleapis.com/maps/api/staticmap?center=${loc}&zoom=18&size=600x300&maptype=roadmap&markers=color:red%7c${loc}&key=${this.apiKey}`;
      const response = await fetch(url);
      const blob = await response.blob();

      return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result as string;
          resolve(base64data);
        };
        reader.onerror = reject;
      });
    },

    clearData() {
      this.selectedImage = null;
      this.marker = {} as Marker;
      this.selectedLocation = {};
      this.location = {};
      this.message = "";
      this.center = {
        lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
        lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
      };
    },
    selectLocation() {
      (this.selectedImage = null),
        (this.locationVisible = false),
        (this.location = this.selectedLocation);
      this.message = this.selectedLocation.text;
      this.selectedLocation = {};
    },

    resetLocation() {
      this.message = "";
      this.selectedLocation = {};
      this.marker = {} as Marker;
      this.center = {
        lat: parseFloat(process.env.VUE_APP_CITY_CENTER_LATITUDE),
        lng: parseFloat(process.env.VUE_APP_CITY_CENTER_LONGITUDE),
      };
      this.locationVisible = false;
    },
  },
  components: { InputText, Button, Dialog, GMapMarker, GMapMap },
});
