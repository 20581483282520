import { createApp } from "vue";
import PrimeVue from "@/plugins/primevue";

import "primeicons/primeicons.css";
import "@/assets/scss/main.scss";

import App from "@/App.vue";
import router from "@/plugins/router";
import store from "@/plugins/store";
import { axios, VueAxios, loadAuthenticatedUser } from "@/plugins/axios";
import moment from "@/plugins/moment";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import i18n, { loadLocaleMessagesAsync } from "@/plugins/i18n";
import Tooltip from "primevue/tooltip";
import { useFirebase } from "@/plugins/firebase";
import VueShowdown from "vue-showdown";
import { directus } from "@/plugins/directus";
import { AxiosInstance } from "axios";
import gtm from "@/plugins/gtm";

Promise.all([loadAuthenticatedUser(), loadLocaleMessagesAsync()]).finally(
  () => {
    const app = createApp(App)
      .use(VueGoogleMaps, {
        load: {
          key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
          libraries: "places",
        },
      })
      .use(PrimeVue)
      .use(router)
      .use(store)
      .use(i18n)
      .use(VueShowdown)
      .use(VueAxios, axios);

    if (
      process.env.VUE_APP_GTM_ENABLE === "true" &&
      process.env.VUE_APP_GTM_ID
    ) {
      app.use(gtm);
    }

    app.directive("tooltip", Tooltip);
    app.config.globalProperties.$moment = moment;
    app.config.globalProperties.$firebase = useFirebase();
    app.config.globalProperties.$directus = directus;

    app.mount("#app");
  },
);

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    // eslint-disable-next-line
    $moment: any;
    // eslint-disable-next-line
    $store: any;
    $firebase: firebase.default.app.App | null;
    $directus: AxiosInstance;
  }
}
